import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Slide from "react-reveal/Slide";

import { THEME_COLORS } from "../../../ThemeConfig";
import AboutYou from "./AboutYou";
import ChangePassword from "./ChangePassword";
import ExpertDetails from "./ExpertDetails";
import WorkingStatus from "./WorkingStatus";
import YourBio from "./YourBio";
import Documents from "./Documents";
import BankAccount from "./BankAccount";
import SuccessToast from "./toast/SuccessToast";
import { Context } from "../../../contexts/Store";
import { useNavigate } from "react-router-dom";
import CancelWarningModal from "./modals/CancelWarningModal";
import AttentionModal from "./modals/AttentionModal";
import DeleteWarnigModal from "./modals/DeleteWarnigModal";
import RevokeWarnigModal from "./modals/RevokeWarnigModal";
import NoCalenderModal from "./modals/NoCalenderModal";
import { expert } from "../../../axiosConfig";
import { set } from "date-fns";

const ManageProfilePage = ({isCalanderAccess}) => {
    const { state, dispatch } = useContext(Context);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showAttentionModal, setShowAttentionModal] = useState(false);
    const [deleteModal, setdeleteModal] = useState(false);
    const [revokeModal, setRevokeModal] = useState(false);
    const [noCalenderModal, setNoCalenderModal] = useState(false);
    const [isCalender, setIsCalender] = useState(isCalanderAccess || false);
    const active_session = state.user_details.active_session;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const access_token = state.user_details.access_token;
    const [PendingModal, setdPendingModal] = useState(false);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    useEffect(() => {
        const timeId = setTimeout(() => {
            setShowSuccessToast(false);
        }, 2000);
        return () => {
            clearTimeout(timeId);
        };
    }, [showSuccessToast]);

    const handleOption = (opt) => {
        dispatch({
            type: "PROFILE_OPTION",
            activeOption: opt,
        });
    };

    const deleteAccount = () => {
        // delete account
    };

    const navigate = useNavigate();

    return (
        <Cover
            onClick={(e) => {
                setShowAttentionModal(true);
                e.stopPropagation();
            }}
        >
            <Slide bottom>
                <Main
                    className={
                        (showAttentionModal ||
                            showSuccessToast ||
                            deleteModal || revokeModal || noCalenderModal) &&
                        "modal-active"
                    }
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Wrapper>
                        <Left>
                            <MenuItems>
                                <Item
                                    className={
                                        state.activeOption === "about" &&
                                        "active"
                                    }
                                    onClick={() => handleOption("about")}
                                >
                                    About you
                                </Item>
                                <Item
                                    className={
                                        state.activeOption === "password" &&
                                        "active"
                                    }
                                    onClick={() => handleOption("password")}
                                >
                                    Change Password
                                </Item>
                                <Item
                                    className={
                                        state.activeOption === "details" &&
                                        "active"
                                    }
                                    onClick={() => handleOption("details")}
                                >
                                    Expert Details
                                </Item>
                                <Item
                                    className={
                                        state.activeOption === "status" &&
                                        "active"
                                    }
                                    onClick={() => handleOption("status")}
                                >
                                    Working Status
                                </Item>

                                <Item
                                    className={
                                        state.activeOption === "documents" &&
                                        "active"
                                    }
                                    onClick={() => handleOption("documents")}
                                >
                                    Documents
                                </Item>
                            </MenuItems>
                            {isCalender && (
                                <RevokeButton onClick={() => setRevokeModal(true)}>
                                Revoke Calendar
                            </RevokeButton>
                            )}
                            {!isCalender && (
                                <RevokeButton onClick={() => setNoCalenderModal(true)}>
                                Revoke Calendar
                            </RevokeButton>
                            )}
                            <Button onClick={() => setdeleteModal(true)}>
                                Delete Account
                            </Button>
                        </Left>
                        <Right>
                            {state.activeOption === "about" && (
                                <AboutYou
                                    setShowSuccessToast={setShowSuccessToast}
                                    showAttentionModal={showAttentionModal}
                                    setShowAttentionModal={
                                        setShowAttentionModal
                                    }
                                />
                            )}
                            {state.activeOption === "password" && (
                                <ChangePassword
                                    setShowSuccessToast={setShowSuccessToast}
                                    showAttentionModal={showAttentionModal}
                                    setShowAttentionModal={
                                        setShowAttentionModal
                                    }
                                />
                            )}
                            {state.activeOption === "details" && (
                                <ExpertDetails
                                    setShowSuccessToast={setShowSuccessToast}
                                    showAttentionModal={showAttentionModal}
                                    setShowAttentionModal={
                                        setShowAttentionModal
                                    }
                                />
                            )}
                            {state.activeOption === "status" && (
                                <WorkingStatus
                                    setShowSuccessToast={setShowSuccessToast}
                                    showAttentionModal={showAttentionModal}
                                    setShowAttentionModal={
                                        setShowAttentionModal
                                    }
                                />
                            )}
                            {state.activeOption === "bio" && (
                                <YourBio
                                    setShowSuccessToast={setShowSuccessToast}
                                    showAttentionModal={showAttentionModal}
                                    setShowAttentionModal={
                                        setShowAttentionModal
                                    }
                                />
                            )}
                            {state.activeOption === "documents" && (
                                <Documents
                                    setShowSuccessToast={setShowSuccessToast}
                                    showAttentionModal={showAttentionModal}
                                    setShowAttentionModal={
                                        setShowAttentionModal
                                    }
                                />
                            )}
                            {state.activeOption === "account" && (
                                <BankAccount
                                    setShowSuccessToast={setShowSuccessToast}
                                    showAttentionModal={showAttentionModal}
                                    setShowAttentionModal={
                                        setShowAttentionModal
                                    }
                                />
                            )}
                        </Right>
                    </Wrapper>
                    {revokeModal && (
                        <RevokeWarnigModal
                            isModal={revokeModal}
                            setIsModal={setRevokeModal}
                        />
                    )}
                    {noCalenderModal && (
                        <NoCalenderModal
                            isModal={noCalenderModal}
                            setIsModal={setNoCalenderModal}
                        />
                    )}
                    {deleteModal && (
                        <DeleteWarnigModal
                            isModal={deleteModal}
                            setIsModal={setdeleteModal}
                        />
                    )}
                    {showSuccessToast && (
                        <Modal>
                            <SuccessToast />
                        </Modal>
                    )}
                    {showAttentionModal && (
                        <CancelWarningModal
                            isModal={showAttentionModal}
                            setIsModal={setShowAttentionModal}
                        />
                    )}
                </Main>
            </Slide>
        </Cover>
    );
};

export default ManageProfilePage;

const Cover = styled.div`
    width: 100vw;
    padding-top: 69.6px;
    background: ${THEME_COLORS.elevation_expert_e9};
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`;
const Main = styled.div`
    background-color: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_expert_e3};
    border-radius: 24px 24px 0px 0px;
    &.modal-active {
        height: calc(100vh - 69.6px);
        overflow-y: hidden;
    }
`;
const Wrapper = styled.div`
    width: 80%;
    padding: 80px 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 1280px) {
        width: 85%;
    }
`;
const Left = styled.div`
    width: 16.5%;
    @media all and (max-width: 1280px) {
        width: 20%;
    }
    @media all and (max-width: 980px) {
        width: 25%;
    }
`;
const MenuItems = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 7px 0px 32px;
    border-bottom: 1px solid ${THEME_COLORS.light_400};
`;
const Item = styled.li`
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    padding: 3px 0px;
    color: ${THEME_COLORS.dark_300};
    cursor: pointer;
    &.active {
        color: ${THEME_COLORS.black};
        border-bottom: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
    }
    &:last-child {
        margin-bottom: 0;
    }
`;
const Button = styled.button`
    margin-top: 10px;
    margin-left: auto;
    display: block;
    font-weight: 500;
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 6px;
    color: ${THEME_COLORS.chips_red_on_container};
    cursor: pointer;
    &:hover {
        background-color: ${THEME_COLORS.light_200};
    }
`;
const RevokeButton = styled.button`
    margin-top: 32px;
    margin-left: auto;
    display: block;
    font-weight: 500;
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 6px;
    color: ${THEME_COLORS.chips_orange_on_container};
    cursor: pointer;
    &:hover {
        background-color: ${THEME_COLORS.light_200};
    }
`;
const Modal = styled.div``;
const Right = styled.div`
    width: 54%;
    height: 100%;
    @media all and (max-width: 1280px) {
        width: 60%;
    }
`;
