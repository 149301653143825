import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { general, authentication } from '../../axiosConfig'
import { Context } from '../../contexts/Store'

function RegisterPage() {
  const [checked, setChecked] = useState()
  const [isChecked, setIsChecked] = useState(false)
  const [number, setNumber] = useState('')
  const [id, setId] = useState('')
  const [errorMessage, setErrorMessage] = useState([])
  const [isLoading, setLoading] = useState(false)
  const { dispatch } = useContext(Context)
  const navigate = useNavigate()

  useEffect(() => {
    let user_details = localStorage.getItem('user_details')
    user_details = JSON.parse(user_details)
    dispatch({ type: 'UPDATE_USER', user_details: user_details })
  }, [])

  const handleInputChange = (e) => {
    let inputValue = e.target.value
    setNumber(inputValue)
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const response = await authentication.post('/signup/', {
        email: number,
      })
      const { StatusCode, data } = response.data
      if (StatusCode == 6000) {
        dispatch({
          type: 'UPDATE_USER',
          user_details: {
            email: number,
          },
        })
        dispatch({
          type: 'UPDATE_NEW_REGISTERATION_STATUS',
          nrs: true,
        })
        navigate('/auth/otp-page')
      } else {
        setErrorMessage(response.data.data.message)
      }
    } catch (error) {
      console.error('Error creating post sss:', error.response.data.message)
      setErrorMessage(error.response.data.data.message)
    } finally {
      setLoading(false)
    }

    setTimeout(() => {
      setErrorMessage('')
    }, 3000)
  }

  return (
    <>
      <Main>
        <Wrapper>
          <Left>
            <ImageBox>
              <img
                src={require('../../assets/image/loginpage/loginpageimage.png')}
                alt="loginimage"
              />
            </ImageBox>
          </Left>
          <Right>
            <LogoBox>
              <img
                src={require('../../assets/image/loginpage/logo.svg').default}
                alt="logo"
              />
            </LogoBox>
            <TopText>
              <Titile>Let’s Get Started!</Titile>
              <SubTitile>
                Join in assisting someone to reach a brighter future.
              </SubTitile>
            </TopText>
            <InputBox>
              <input
                type="email"
                // maxLength={10}
                className="active"
                placeholder="Enter your Email ID"
                value={number}
                onChange={handleInputChange}
              />
            </InputBox>
            <Box>
              <CheckBox onClick={() => setIsChecked(!isChecked)}>
                {isChecked && (
                  <img
                    src={
                      require('../../assets/image/loginpage/Check-box.svg')
                        .default
                    }
                    alt="checkbox"
                  />
                )}
              </CheckBox>{' '}
              {''}
              <Check>
                I Agree to mindmitra {''}{' '}
                <TCBox to={'/auth/terms-and-conditions'}>
                  {' '}
                  Terms and Conditions
                </TCBox>
                {' '} and
                <TCBox to={'/auth/privacy-policy'}>
                  {' '}
                  Privacy Policy
                </TCBox>
                {' '}, which include granting permission to access your Google Calendar for session management.
              </Check>
            </Box>
            <ButtonBox
              type="submit"
              className={isChecked && 'active'}
              onClick={() => {
                if (isChecked) {
                  if (number) {
                    handleSubmit()
                  }
                } else {
                  setErrorMessage('Please agree terms and conditions')
                }
              }}
            >
              {isLoading ? 'Loading...' : 'Register'}
            </ButtonBox>

            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}

            <Expert>
              Login as an <Log to="/auth/login-page">Expert!</Log>{' '}
            </Expert>
          </Right>
        </Wrapper>
      </Main>
    </>
  )
}

export default RegisterPage
const Main = styled.div`
  padding: 70px 0;
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  width: 90%;
  margin: 0 auto;
  @media all and (max-width: 980px) {
    flex-direction: column-reverse;
    gap: 50px;
  }
`
const Left = styled.div`
  width: 55%;
  @media all and (max-width: 1440px) {
  }
  @media all and (max-width: 1050px) {
    width: 64%;
  }
  @media all and (max-width: 980px) {
    width: 85%;
  }
`
const ImageBox = styled.div`
  align-items: center;
  width: 100%;
  /* @media all and (max-width: 1440px) {
    width: 90%;
  } */
  @media all and (max-width: 980px) {
    width: 100%;
  }
  img {
    width: 100%;
    display: block;
  }
`
const Right = styled.div`
  /* margin: 140px 0 0 0; */
  width: 40%;
  @media all and (max-width: 1440px) {
    /* margin: 140px 0 0 0; */
    /* width: 36%; */
  }
  @media all and (max-width: 980px) {
    width: 75%;
  }
`
const LogoBox = styled.div`
  width: 16%;
  /* margin-bottom: 20px; */
`
const TopText = styled.div``
const Titile = styled.h5`
  font-size: 24px;
  font-weight: 500;
  margin: 30px 0 7px 0;

  /* font-family: "dm_sans_bold"; */
  @media all and (max-width: 1440px) {
    margin-top: 15px;
  }
  @media all and (max-width: 1050px) {
    font-size: 22px;
  }
`
const SubTitile = styled.p`
  color: #7a838a;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  @media all and (max-width: 1050px) {
    font-size: 14px;
  }
`
const Bar = styled.p`
  margin: 0;
  color: #dededf;
  margin-left: 5px;
  border-right: 1px solid #dededf;
  width: 5px;
  height: 20px;
`
const InputBox = styled.div`
  background-color: #f6f7f9;
  /* height: 40px; */
  display: flex;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  align-items: center;
  margin-top: 50px;
  width: 80%;

  /* @media all and (max-width: 1440px) {
    margin-top: 25px;
    margin-bottom: 10px;

    height: 35px;
  } */
  @media all and (max-width: 1280px) {
    height: 30px;
  }

  input {
    border: none;
    /* background-color: #f6f7f9; */
    padding-left: 10px;
    /* height: 20px; */
    font-size: 18px;
    width: 100%;
    cursor: pointer;
    @media all and (max-width: 1280px) {
      font-size: 16px;
    }
    :focus {
      outline: none;
      /* border: 1px solid red; */
    }

    ::placeholder {
      font-size: 16px;
      font-weight: 400;
    }
  }
`
const ButtonBox = styled.button`
  background-color: #366ee3;
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 8px;
  margin-top: 20px;
  width: 80%;
  border: none;
  font-size: 18px;
  opacity: 0.5;
  cursor: not-allowed;
  &.active {
    opacity: 1 !important;
    cursor: pointer;
  }
  @media all and (max-width: 1050px) {
    padding: 15px 130px;
  }
`
const Log = styled(Link)`
  color: #2b3990;
  text-decoration: none;
`
const Expert = styled.h5`
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  text-align: center;
  color: #818181;
  margin-top: 20px;
  width: 80%;
  /* @media all and (max-width: 1440px) {
    margin-top: 11%;
  } */
`
const ErrorText = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`

const Check = styled.div`
  // display: flex;
  color: #818181;
  margin-left: 10px;
  @media all and (max-width: 1280px) {
    font-size: 14px;
  }
`
const TCBox = styled(Link)`
  text-decoration: underline;
  color: #366ee3;
  margin-left: 5px;
`

const Inputs = styled.div`
  margin-bottom: 10px;
`
const Box = styled.div`
  margin-top: 20px;
  display: flex;
  width: 80%;
`
const CheckBox = styled.div`
  width: 50px;
  height: 19px;
  border-radius: 5px;
  margin-top: 1px;
  cursor: pointer;
  border: 1px solid #c1bfbf;
  @media all and (max-width: 1280px) {
    margin-top: 0;
    width: 15px;
    height: 15px;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`
