import React from "react";
import { Link, useNavigate } from 'react-router-dom'
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import ButtonTypeOne from "../../includes/buttons/ButtonTypeOne";

const ConfirmationModal = ({
    showConfirmationModal,
    setShowConfirmationModal,
    handleRedirect,
    url,
}) => {
    const handleOuterClick = (e) => {
        const element = document.querySelector(".outer-click");
        if (!element.contains(e.target)) {
            setShowConfirmationModal(false);
        }
    };

    const handleCancel = () => {
        setShowConfirmationModal(false);
    };

    const handleConfirm = () => {
        setShowConfirmationModal(false);
        handleRedirect(url); // Redirect after confirmation
    };

    return (
        <>
            {showConfirmationModal && (
                <>
                    <Background
                        onClick={(e) =>
                            showConfirmationModal && handleOuterClick(e)
                        }
                    ></Background>
                    <Container className="outer-click">
                        <Title>Google Calendar Access Required</Title>
                        <SubTitle>
                        To enable key features, our Minmitra platform requires access to your Google Calendar <br />
                        For more details, please refer to our <Link to="/privacy-policy/?section=10">Privacy Policy</Link>
                        </SubTitle>
                        <ButtonContainer>
                            <ButtonTypeOne
                                text="Cancel"
                                color={THEME_COLORS.dark_500}
                                bkgcolor={THEME_COLORS.white}
                                border={THEME_COLORS.light_400}
                                onclick={handleCancel}
                            />
                            <ButtonTypeOne
                                text="Access Calendar"
                                color={THEME_COLORS.white}
                                bkgcolor={THEME_COLORS.chips_blue_on_container_2}
                                onclick={handleConfirm}
                            />
                        </ButtonContainer>
                    </Container>
                </>
            )}
        </>
    );
};

export default ConfirmationModal;

const Background = styled.div`
    background: ${THEME_COLORS.elevation_expert_e9};
    position: fixed;
    opacity: 0.4;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 8888;
`;

const Container = styled.div`
    position: fixed;
    width: 411px;
    padding: 40px;
    background: ${THEME_COLORS.white};
    border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    border-radius: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
`;

const Title = styled.h4`
    font-weight: 500;
    font-size: 20px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 16px;
`;

const SubTitle = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 32px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const URL = styled.a`
    color: ${THEME_COLORS.chips_blue_on_container};
`;