import React, { useState } from "react";
import styled from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig.js";
import { TableRow } from "@mui/material";

const TermsAndConditions = () => {
    const [option, setOption] = useState(1);

    return (
        <Main>
            <Head>
                <Title>Terms of Services</Title>
                <Update>Updated January 30</Update>
            </Head>
            <Content>
                <Left>
                    <TableHead>Table of Contents</TableHead>
                    <TableItem
                        className={option === 1 && "active"}
                        onClick={() => setOption(1)}
                    >
                        Accepting the Terms
                    </TableItem>{" "}
                    <br />
                    <TableItem
                        className={option === 2 && "active"}
                        onClick={() => setOption(2)}
                    >
                        Sign Up
                    </TableItem>
                    <TableItem
                        className={option === 3 && "active"}
                        onClick={() => setOption(3)}
                    >
                        Scheduling and adherence to the committed schedule
                    </TableItem>
                    <TableItem
                        className={option === 4 && "active"}
                        onClick={() => setOption(4)}
                    >
                        Quality standards- During and post consultation
                    </TableItem>
                    <TableItem
                        className={option === 5 && "active"}
                        onClick={() => setOption(5)}
                    >
                        Patient privacy & confidentiality
                    </TableItem>
                    <TableItem
                        className={option === 6 && "active"}
                        onClick={() => setOption(6)}
                    >
                        Acceptance of lawful conduct
                    </TableItem>{" "}
                    <TableItem
                        className={option === 7 && "active"}
                        onClick={() => setOption(7)}
                    >
                        Commercial Terms
                    </TableItem>
                    <TableItem
                        className={option === 8 && "active"}
                        onClick={() => setOption(8)}
                    >
                        Terms of Termination
                    </TableItem>
                    <TableItem
                        className={option === 9 && "active"}
                        onClick={() => setOption(9)}
                    >
                        Acceptance of Terms & Conditions
                    </TableItem>
                </Left>
                <Right>
                    {option === 1 && (
                        <>
                            <SubTitle>
                            Accepting the Terms
                            </SubTitle>
                            <Description>
                                <Text>
                                Mindmitra facilitates the connection between experts and users, and the relationship between them will be governed independently according to Applicable Laws, and as applicable to you and the Patient.
                                </Text>
                                <Text>
                                The following details are contract or terms of service regarding communication between experts and users on online platform Mindmitra and this terms and conditions ensures that experts respond promptly to user inquiries and adhere to relevant laws and guidelines. If experts fail to comply, Mindmitra has the right to replace or remove them from the Mindmitra.
                                </Text>
                            </Description>
                        </>
                    )}
                    {option === 2 && (
                        <>
                            <SubTitle>Sign Up</SubTitle>
                            <Description>
                                <Text>
                                Experts should give proper and complete information during sign up. Mind Mitra team will verify the details before approving an expert on its platform
                                </Text>
                                <Text>
                                Experts require to verify and certify that all personal and professional information provided to Mindmitra, including registration number, PAN (Permanent Account Number), bank details, and contact information, is accurate and true. In case wrong bank account details are provided by Expert, Mindmitra will not be responsible for loss of money, if any.
                                </Text>
                            </Description>
                        </>
                    )}
                    {option === 3 && (
                        <>
                            <SubTitle>
                                Scheduling and adherence to the committed
                                schedule
                            </SubTitle>
                            <Description>
                                <Text>
                                If an expert on Mindmitra has marked themselves available for a consultation and a consultation is auto-allocated to them, they must respond to the user within 24 hours of accepting the consultation during the active consultation period.
                                </Text>
                                <Text>
                                The expert shall provide its services from any location provided that the other terms and conditions as agreed between the parties are met and the quality of services is not compromised.
                                </Text>
                                <Text>
                                Experts must respond promptly to user communications. If an expert fails to comply with applicable laws, rules, regulations, or guidelines, Mindmitra reserves the right to replace them.
                                </Text>
                                <Text>
                                Mindmitra has the right to reassign the session to any of the experts from the panel, in case the currently assigned expert failed to attend session. Experts should inform to mindmitra admin via email or contact admin option at least 48 hours before, regarding any inability to attend the committed session due to any reasons.
                                </Text>
                                <Text>
                                Experts need to promptly indicate their availability for online appointments, and appointment requests will be accepted based on the availability indicated on the Mindmitra platform. If there is any change in your availability from the default schedule, it should be updated in the Mindmitra expert site.
                                </Text>
                                <Text>
                                Please note that the new session notification will be sent to all experts with the matching expertise and the session will be assigned to the expert who ever accept the session first.
                                </Text>
                                <Text>
                                If an expert needs to change their status to 'unavailable', they should do so after completing all open consultations. If the expert leaves a consultation open and changes their status to 'unavailable', they understand that they may be liable to pay a penalty imposed by Mindmitra in such cases
                                </Text>
                            </Description>
                        </>
                    )}
                    {option === 4 && (
                        <>
                            <SubTitle>
                                Quality standards- During and post consultation
                            </SubTitle>
                            <Description>
                                <Text>
                                Experts are required to adhere strictly to accepted standards of clinical practice, follow clinical protocols, procedures, and applicable regulations while providing their services.
                                </Text>
                                <Text>
                                Experts must provide a consultation case report to Mindmitra, and Mindmitra will then provide a summary report to the user via the e-prescription module for all users.
                                </Text>
                                <Text>
                                Experts on Mindmitra are prohibited from prescribing certain medications, including medical termination of pregnancy medication, sedatives, hypnotics, opioids, schedule X drugs, or fourth-generation antibiotics. If any of these drugs are indicated for treatment during a consultation, the expert must refer the user for a direct psychiatry/physical consultation.
                                </Text>
                                <Text>
                                Experts must provide case reports or e-prescriptions exclusively through the prescription module to Mindmitra. The expert agrees to be responsible and liable for the content of the case report.
                                </Text>
                                <Text>
                                Expert shall be dressed appropriately while providing services through video mode to the Users.
                                </Text>
                                <Text>
                                In the event of emergency or life-threatening situations, the expert should advise the patient to seek in-person interaction with a healthcare provider rather than providing diagnosis or consultation. If alternative care is not available promptly, the expert may, in compliance with telemedicine practice guidelines, provide first aid, life-saving measures, counseling, and advise the patient to visit a healthcare provider in person
                                </Text>
                                <Text>
                                The expert acknowledges that it's their responsibility to ensure the accuracy and completeness of the information provided by the user. Mindmitra will not be held liable for any errors in the information exchanged between the expert and the user.
                                </Text>
                                <Text>
                                Mindmitra doesn't guarantee uninterrupted communication, and the expert acknowledges that Mindmitra won't be held liable for any issues regarding the delivery or timing of communication, despite its efforts.
                                </Text>{" "}
                                <Text>
                                    The expert agrees to use their professional
                                    discretion to obtain the patient information
                                    necessary, including history, examination
                                    findings, investigation reports, past
                                    records, etc., to exercise proper clinical
                                    judgment.
                                </Text>{" "}
                                <Text>
                                    The expert hereby agrees to allow
                                    Mindmitra's medical team to conduct audits
                                    of their consultations on the Mindmitra
                                    platform for the purpose of enhancing
                                    treatment quality, user experience, and
                                    related processes. The expert acknowledges
                                    that personal information may be included in
                                    these audits and that it will be processed
                                    in accordance with Mindmitra's Privacy
                                    Policy.
                                </Text>{" "}
                                <Text>
                                    Don't give, solicit, or receive any gift,
                                    gratuity, commission, or bonus in
                                    consideration of or return for the
                                    referring, recommending, or procuring of any
                                    Patient for medical or other treatment.
                                </Text>{" "}
                                <Text>
                                    The expert agrees to verify and confirm the
                                    patient's identity using various means such
                                    as name, age, address, email ID, phone
                                    number, registered ID, or any other
                                    identification deemed appropriate before the
                                    teleconsultation. In cases where the patient
                                    is a minor, the expert undertakes to
                                    initiate the consultation only after
                                    confirming the age of the minor patient.
                                    Additionally, the consultation will proceed
                                    only if the minor is accompanied by an
                                    adult, whose identity should also be
                                    ascertained.
                                </Text>{" "}
                            </Description>
                        </>
                    )}
                    {option === 5 && (
                        <>
                            <SubTitle>
                                Patient privacy & confidentiality
                            </SubTitle>
                            <Description>
                                <Text>
                                    The expert agrees to hold the confidential
                                    information in strict confidence in
                                    accordance with the provisions outlined in
                                    the agreement and applicable laws. They will
                                    protect the confidential information by
                                    employing the highest degree of care and
                                    security precautions to prevent its
                                    unauthorized use, dissemination, or
                                    publication.
                                </Text>
                                <Text>
                                    It is further understood by the Expert that
                                    the information that is disclosed by the
                                    User at the time of consultation is personal
                                    information and is subject to all applicable
                                    privacy laws, shall be confidential in
                                    nature and subject to User and Expert
                                    privilege.
                                </Text>
                            </Description>
                        </>
                    )}
                    {option === 6 && (
                        <>
                            <SubTitle>Acceptance of lawful conduct</SubTitle>
                            <Description>
                                <Text>
                                    The expert acknowledges that by acting in
                                    accordance with this agreement, the
                                    characteristics of an expert-patient
                                    relationship between themselves and the
                                    users seeking mental healthcare consultation
                                    from Mindmitra are not affected or impacted.
                                    Therefore, the terms of the Indian Medical
                                    Council’s Professional Conduct, Etiquette
                                    and Ethics Regulations, 2002 (MCI
                                    Regulations) or WHO (Code of Ethics)
                                    Regulations, as applicable, will apply to
                                    the expert in their interactions with the
                                    users undertaken pursuant to this agreement.
                                </Text>
                                <Text>
                                    The expert acknowledges that if Mindmitra
                                    finds them to be in violation of any
                                    applicable laws, rules, regulations, or
                                    guidelines set out by the authorities,
                                    Mindmitra shall be entitled to cancel the
                                    consultation with the expert or take any
                                    other legal action as may be required.
                                </Text>
                                <Text>
                                    The expert agrees to perform services
                                    strictly in accordance with the Telemedicine
                                    Practice Guidelines dated March 25, 2020,
                                    issued by the Board of Governors ("Tele
                                    medicine Guidelines"), or any other
                                    applicable guideline related to telemedicine
                                    consultations.
                                </Text>
                                <Text>
                                    If an expert came to know of physical abuse,
                                    sexual misconduct (especially involving
                                    minors), or user self-harm (including
                                    planned, attempted, or completed suicide),
                                    they agree to report such events to
                                    Mindmitra immediately via email:
                                    expertcare@mymindmitra.com within 5 hours.
                                </Text>
                            </Description>
                        </>
                    )}
                    {option === 7 && (
                        <>
                            <SubTitle>Commercial Terms</SubTitle>
                            <Description>
                                <Text>
                                Mindmitra’s standard terms for settlement of payments to Experts shall apply.
                                </Text>    
                                <Table>
                                    <TableRow>
                                        <TableHeading>Expert slabs</TableHeading>
                                        <TableHeading>Expert split</TableHeading>
                                        <TableHeading>MM split</TableHeading>
                                    </TableRow>
                                    <TableRow>
                                        <TableData>Gold</TableData>
                                        <TableData>75%</TableData>
                                        <TableData>25%</TableData>
                                    </TableRow>
                                    <TableRow>
                                        <TableData>Platinum</TableData>
                                        <TableData>80%</TableData>
                                        <TableData>20%</TableData>
                                    </TableRow>
                                </Table>
                                <Text>
                                    All experts who sign up with Mind Mitra will
                                    be added to Gold slab by default. Experts
                                    with minimum 10 year relevant work
                                    experience OR PhD OR RCI license will be
                                    upgraded to Platinum slab after completing 3
                                    successful consulting session.
                                </Text>
                                <Text>
                                    Mindmitra shall provide a bill of
                                    supply/invoice to Mindmitra for the services
                                    rendered under this agreement, specifying
                                    the patient’s name, e-prescription serial
                                    number and such other details as may be
                                    required from time to time.
                                </Text>
                                <Text>
                                    All payments to Expert shall be subject to
                                    applicable tax deductions/withholdings under
                                    the Income Tax Act, 1961.
                                </Text>
                            </Description>
                        </>
                    )}
                    {option === 8 && (
                        <>
                            <SubTitle>Terms of Termination</SubTitle>
                            <Description>
                                <Text>
                                    The agreement shall remain valid till it is
                                    terminated in accordance with its
                                    provisions.
                                </Text>
                                <Text>
                                    Mindmitra or the Expert may terminate this
                                    agreement upon providing Fifteen (15)
                                    business days’ notice in writing including
                                    by email. However, if either party is in
                                    material breach of this agreement and such
                                    breach is not cured within thirty (30)
                                    business days of being notified in writing
                                    of the breach, then the non-breaching party
                                    may immediately terminate this agreement.
                                </Text>
                                <Text>
                                    Further, Mindmitra reserves the right to
                                    suspend or terminate services provided
                                    through the Website and under this
                                    agreement, with or without notice and to
                                    exercise any other remedy available under
                                    law, in case of the occurrence of the
                                    following events:
                                </Text>{" "}
                                <Text>
                                    Expert contravenes applicable laws; and/or a
                                    third-party reports violation of any of its
                                    right as a result of Consult.
                                </Text>
                            </Description>
                        </>
                    )}
                    {option === 9 && (
                        <>
                            <SubTitle>Acceptance of Terms & Conditions</SubTitle>
                            <Description>
                                <Text>
                                Experts are prohibited from using any platform other than Mindmitra for interacting or communicating to provide services. Any attempt by the expert to interact with users through external means of communication would constitute a violation of this agreement. In such cases, the expert is solely responsible for indemnifying Mindmitra for all losses and damages suffered as a consequence.
                                </Text>
                                <Text>
                                The expert agrees to these standard terms and conditions by ticking on the acceptance button displayed on the signup page of the Mindmitra platform.
                                </Text>
                                <Text>
                                “I agree to the Terms & Conditions and Privacy Policy, including permission for this app to manage my Google Calendar events (create, modify, view, and delete appointments) and send notifications related to scheduled sessions. I understand that my calendar data will be accessed solely for appointment management purpose”
                                </Text>{" "}
                                <Text>
                                If you have any questions or concerns about this policy, it might be best to reach out to Mindmitra directly for clarification at expertcare@mymindmitra.com
                                </Text>
                            </Description>
                        </>
                    )}
                </Right>
            </Content>
        </Main>
    );
};

export default TermsAndConditions;

const Main = styled.div`
    width: 80%;
    margin: 0 auto;
    padding: 36px 0 100px;
    @media all and (max-width: 1280px) {
        width: 85%;
    }
`;
const Head = styled.div`
    text-align: center;
`;
const Title = styled.h4`
    font-weight: 700;
    font-size: 24px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 4px;
`;
const Update = styled.p`
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
`;
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
`;
const Left = styled.div`
    width: 25%;
    height: max-content;
    padding: 16px;
    background-color: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_expert_e4};
    border-radius: 10px;
    @media all and (max-width: 1280px) {
        width: 30%;
    }
`;
const TableHead = styled.h5`
    font-weight: 500;
    font-size: 20px;
    color: ${THEME_COLORS.black};
    margin-bottom: 12px;
`;
const TableItem = styled.button`
    cursor: pointer;
    text-align: left !important;
    padding: 3px 0px;
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.dark_300};
    margin-bottom: 10px;
    &.active {
        color: ${THEME_COLORS.black};
        border-bottom: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
    }
`;
const Right = styled.div`
    width: 73%;
    /* min-height: 742px; */
    height: max-content;
    padding: 32px 32px 32px 21px;
    border-radius: 10px;
    background-color: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_expert_e4};
    @media all and (max-width: 1280px) {
        width: 68%;
    }
`;
const SubTitle = styled.h5`
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
`;
const Description = styled.p`
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
    a {
        margin-left: 5px;
        color: #366ee3;
    }
`;

const Text = styled.p`
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
    a {
        margin-left: 5px;
        color: #366ee3;
        font-size: 14px;
        font-weight: 400;
    }
`;

const Table = styled.table`
    width: 100%;
    border: 1px solid ${THEME_COLORS.light_200};
    border-collapse: collapse;
    margin-bottom: 20px;
`;

const TableHeading = styled.th`
    border: 1px solid ${THEME_COLORS.light_200};
    padding: 8px;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: ${THEME_COLORS.text_title};
`;

const TableData = styled.td`
    padding: 8px;
    border: 1px solid ${THEME_COLORS.light_200};
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
`;


