import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig.js";

const PrivacyPolicy = () => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const query = useQuery();
    const section = parseInt(query.get("section", 1));

    const [option, setOption] = useState(section || 1);
    

    return (
        <Main>
            <Head>
                <Title>Privacy Policy</Title>
                <Update>Updated January 30</Update>
            </Head>
            <Content>
                <Left>
                    <TableHead>Table of Contents</TableHead>
                    <TableItem
                        className={option === 1 && "active"}
                        onClick={() => setOption(1)}
                    >
                        About Your Privacy and This Privacy Policy
                    </TableItem>{" "}
                    <br />
                    <TableItem
                        className={option === 2 && "active"}
                        onClick={() => setOption(2)}
                    >
                        Information Collection, Use, and Disclosure
                    </TableItem>
                    <TableItem
                        className={option === 3 && "active"}
                        onClick={() => setOption(3)}
                    >
                        Cookies
                    </TableItem>
                    <br />
                    <TableItem
                        className={option === 4 && "active"}
                        onClick={() => setOption(4)}
                    >
                        Phishing
                    </TableItem>
                    <br />
                    <TableItem
                        className={option === 5 && "active"}
                        onClick={() => setOption(5)}
                    >
                        Links
                    </TableItem>
                    <br />
                    <TableItem
                        className={option === 6 && "active"}
                        onClick={() => setOption(6)}
                    >
                        Security
                    </TableItem>{" "}
                    <br />
                    <TableItem
                        className={option === 7 && "active"}
                        onClick={() => setOption(7)}
                    >
                        Service Providers
                    </TableItem>
                    <TableItem
                        className={option === 8 && "active"}
                        onClick={() => setOption(8)}
                    >
                        International Transfer
                    </TableItem>
                    <TableItem
                        className={option === 9 && "active"}
                        onClick={() => setOption(9)}
                    >
                        Compliance with Laws and Law Enforcement
                    </TableItem>
                    <TableItem
                        className={option === 10 && "active"}
                        onClick={() => setOption(10)}
                    >
                        Accessing Google Calander
                    </TableItem>
                    <TableItem
                        className={option === 11 && "active"}
                        onClick={() => setOption(11)}
                    >
                        Changes to the Privacy Policy
                    </TableItem>
                </Left>
                <Right>
                    {option === 1 && (
                        <>
                            <SubTitle>
                            About Your Privacy and This Privacy Policy
                            </SubTitle>
                            <Description>
                                <Text>
                                We place a great deal of importance on your privacy. We are always putting a lot of consideration, work, resources, technologies, and processes in place to make sure your privacy is protected. Our "Privacy Policy," which is available here, provides information on matters pertaining to your privacy when utilizing our services. Its purpose is to enlighten you about our methods, policies, and processes about the gathering, utilizing, and disclosing of any data you provide on the Platform.
                                </Text>
                                <Text>
                                Our website contains our Terms and Conditions, which include the Privacy Policy. Terms like "we," "our," "us," "Platform," "Counselor," "Counselor Services," “Website”, “expert” and so forth that are used in the Privacy Policy have the same meanings as those found in our Terms and Conditions document. You acknowledge and agree to the Terms & Conditions and the Privacy Policy when you use our platform. You should cease using the Platform right away if you disagree with the terms and conditions stated in the privacy statement. You confirm that you have read the Terms & Conditions and the Privacy Policy and that you comprehend, agree with, and recognize all of the terms stated in both documents by accessing and using our platform.
                                </Text>
                            </Description>
                        </>
                    )}
                    {option === 2 && (
                        <>
                            <SubTitle>Information Collection, Use, and Disclosure</SubTitle>
                            <Description>
                                <Text>
                                By deciding to provide the Information you agree to our methods of collections and use, as well to other terms and provisions of this Privacy Policy. By using our app, you grant us permission to create, modify, view, and delete events in your Google Calendar. We will access your calendar data only to manage appointment scheduling, send meeting invitations, and handle event updates. Your calendar data will be used strictly for scheduling purposes and will not be shared with third parties except as necessary for appointment coordination. You can revoke these permissions at any time through your Google Account settings.
                                </Text>
                                <Text>
                                Protecting this Information is a top priority for us. We will never sell or rent any Information you shared in the Platform. Other than in the limited ways detailed in this Privacy Policy, we will never use or disclose any Information unless you specifically and explicitly requested or approved us to do so.
                                </Text>
                                <SubHeading>
                                The Information may be used for the following purposes:
                                </SubHeading>
                                <List>
                                    <ListItem>
                                    To contact you or provide you with information, alerts and suggestions that are related to the service.
                                    </ListItem>
                                    <ListItem>
                                    Billing-related purposes.
                                    </ListItem>
                                    <ListItem>
                                    To reach out to you, either ourselves or using the appropriate authorities, if either we or a Counselor have a good reason to believe that you or any other person may be in danger or may be either the cause or the victim of a criminal act.
                                    </ListItem>
                                    <ListItem>
                                    To match you with a Counselor.
                                    </ListItem>
                                    <ListItem>
                                    To enable and facilitate the Counselor Services.
                                    </ListItem>
                                    <ListItem>
                                    To supervise, administer and monitor the service.
                                    </ListItem>
                                    <ListItem>  
                                    To measure and improve the quality, the effectiveness and the delivery of our services.
                                    </ListItem>
                                </List>                            
                            </Description>
                        </>
                    )}
                    {option === 3 && (
                        <>
                            <SubTitle>
                            Cookies
                            </SubTitle>
                            <Description>
                                <Text>
                                Like many websites, we use “cookies” to collect information. A “cookie” is a small data file that is transferred to your computer’s hard disk for record-keeping purposes. You can change your browser’s settings so it will stop accepting cookies or to prompt you before accepting a cookie. However, if you do not accept cookies you may not be able to use the Platform.
                                </Text>
                            </Description>
                        </>
                    )}
                    {option === 4 && (
                        <>
                            <SubTitle>
                            Phishing
                            </SubTitle>
                            <Description>
                                <Text>
                                Online identity theft and account hacking, including the practice currently known as “phishing”, are of great concern. You should always be diligent when you are being asked for your account information and you must always make sure you do that in our secure system. We will never request your login information or your credit card information in any non-secure or unsolicited communication (email, phone or otherwise).
                                </Text>
                            </Description>
                        </>
                    )}
                    {option === 5 && (
                        <>
                            <SubTitle>
                            Links
                            </SubTitle>
                            <Description>
                                <Text>
                                The Platform may contain links to other websites, services or offers which are owned, operated or maintained by third parties. If you click on a third-party link, you will be directed to that third website or service. The fact that we link to a website or service is not an endorsement, authorization or representation of our affiliation with that third party, nor is it an endorsement of their privacy or information security policies or practices. We do not have control over third party websites and services and we do not have control over their privacy policies and terms of use.
                                </Text>
                            </Description>
                        </>
                    )}
                    {option === 6 && (
                        <>
                            <SubTitle>Security</SubTitle>
                            <Description>
                                <Text>
                                While using any Internet-based service carries inherent security risks that cannot be 100% prevented, our systems, infrastructure, encryption technology, operation and processes are all designed, built and maintained with your security and privacy in mind. We apply industry standards and best practices to prevent any unauthorized access, use, and disclosure. We comply with or exceed all applicable federal laws, state laws, and regulations regarding data privacy.
                                </Text>
                            </Description>
                        </>
                    )}
                    {option === 7 && (
                        <>
                            <SubTitle>Service Providers</SubTitle>
                            <Description>
                                <Text>
                                We may employ third party companies and individuals to facilitate our Platform, to perform certain tasks which are related to the Platform, or to provide audit, legal, operational or other services for us. These tasks include, but not limited to, customer service, technical maintenance, monitoring, email management and communication, database management, billing and payment processing, reporting and analytics. We will share with them only the minimum necessary information to perform their task for us and only after entering into appropriate confidentiality agreements. We use google meet platform to facilitate video conferencing and your email id will be used to set up the online meeting.
                                </Text>
                            </Description>
                        </>
                    )}
                    {option === 8 && (
                        <>
                            <SubTitle>International Transfer</SubTitle>
                            <Description>
                                <Text>
                                Your information may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction. Regardless of where your data is stored, it will be maintained securely as outlined in this policy. Your consent to our Terms and Conditions followed by your submission of such information represents your agreement to such transfers.
                                </Text>
                            </Description>
                        </>
                    )}
                    {option === 9 && (
                        <>
                            <SubTitle>Compliance with Laws and Law Enforcement</SubTitle>
                            <Description>
                                <Text>
                                We cooperate with government and law enforcement officials to enforce and comply with the law. We may disclose information necessary or appropriate to protect the safety of the public or any person, to respond to claims and legal process (including but not limited to subpoenas), and to prevent or stop activity that may be illegal or dangerous. You should also be aware that Counselors may be obliged to disclose information to law enforcement or other authorities to conform to their professional and legal responsibilities. Specifically, and without limitation, you should be aware that the law requires mental health professionals to disclose information and/or take action in the following cases: (a) reported or suspected abuse of a child or vulnerable adult; (b) serious suicidal potential; (c) threatened harm to another person; (d) court-ordered presentation of treatment.
                                </Text>
                            </Description>
                        </>
                    )}{option === 10 && (
                        <>
                            <SubTitle>Accessing Google Calander</SubTitle>
                            <Description>
                                <Text>
                                By using our app, you grant us permission to create, modify, view, and delete events in your Google Calendar. We will access your calendar data only to manage appointment scheduling, send meeting invitations, and handle event updates. Your calendar data will be used strictly for scheduling purposes and will not be shared with third parties except as necessary for appointment coordination. You can revoke these permissions at any time through your Google Account settings.
                                </Text>
                                <SubHeading>
                                Here's a step-by-step guide to remove Google Calendar access from mymindmitra application:
                                </SubHeading>
                                <List>
                                    <ListItem>Go to your Google Account settings at https://myaccount.google.com</ListItem>
                                    <ListItem>Click on "Security" in the left sidebar</ListItem> 
                                    <ListItem>Scroll down to find "Your connections to third-party apps and services"</ListItem>
                                    <ListItem>Click on "mymindmitra" from "Third-party apps and services"</ListItem>
                                    <ListItem>Click "Delete all connections that you have with mymindmitra"</ListItem>
                                    <ListItem>Confirm your choice</ListItem>
                                </List>
                                <SubHeading>
                                Alternatively, you can:
                                </SubHeading>
                                <List>
                                    <ListItem>Visit "https://myaccount.google.com/connections?filters=3,4&hl=en-GB" directly</ListItem>
                                    <ListItem>Find the app("mymindmitra") you want to remove</ListItem> 
                                    <ListItem>Click "Delete all connections that you have with mymindmitra"</ListItem>
                                    <ListItem>Confirm your choice</ListItem>
                                </List>
                            </Description>
                        </>
                    )}
                    {option === 11 && (
                        <>
                            <SubTitle>Changes to the Privacy Policy</SubTitle>
                            <Description>
                                <Text>
                                We may update this privacy statement at our sole discretion. The date of the last revision of this policy appears at the end of this page. We encourage you to periodically review this page for the latest information on our Privacy Policy and practices. Regardless of changes to our Privacy Policy, we will never use the information you submit under our current privacy notice in a new way without first notifying you and giving you the option to opt out.
                                </Text>
                            </Description>
                        </>
                    )}
                </Right>
            </Content>
        </Main>
    );
};

export default PrivacyPolicy;

const Main = styled.div`
    width: 80%;
    margin: 0 auto;
    padding: 36px 0 100px;
    @media all and (max-width: 1280px) {
        width: 85%;
    }
`;
const Head = styled.div`
    text-align: center;
`;
const Title = styled.h4`
    font-weight: 700;
    font-size: 24px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 4px;
`;
const Update = styled.p`
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
`;
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
`;
const Left = styled.div`
    width: 25%;
    height: max-content;
    padding: 16px;
    background-color: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_expert_e4};
    border-radius: 10px;
    @media all and (max-width: 1280px) {
        width: 30%;
    }
`;
const TableHead = styled.h5`
    font-weight: 500;
    font-size: 20px;
    color: ${THEME_COLORS.black};
    margin-bottom: 12px;
`;
const TableItem = styled.button`
    cursor: pointer;
    text-align: left !important;
    padding: 3px 0px;
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.dark_300};
    margin-bottom: 10px;
    &.active {
        color: ${THEME_COLORS.black};
        border-bottom: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
    }
`;
const Right = styled.div`
    width: 73%;
    /* min-height: 742px; */
    height: max-content;
    padding: 32px 32px 32px 21px;
    border-radius: 10px;
    background-color: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_expert_e4};
    @media all and (max-width: 1280px) {
        width: 68%;
    }
`;
const SubTitle = styled.h5`
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
`;
const SubHeading = styled.h6`
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 15px;
    color: ${THEME_COLORS.text_title};
`;
const Description = styled.p`
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
    a {
        margin-left: 5px;
        color: #366ee3;
    }
`;

const Text = styled.p`
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
    a {
        margin-left: 5px;
        color: #366ee3;
        font-size: 14px;
        font-weight: 400;
    }
`;

const List = styled.ul`
    margin-left: 30px;
    margin-bottom: 20px;
    list-style-type: disc;
`;

const ListItem = styled.li`
    margin-bottom: 2px;
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
`;