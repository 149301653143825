import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { THEME_COLORS } from "../../../../ThemeConfig";
import ButtonTypeOne from "../../../includes/buttons/ButtonTypeOne";
import { Context } from "../../../../contexts/Store";
// import PendingWarningModal from "./PendingWarningModal";
import { expert } from "../../../../axiosConfig";
import PendingWarningModal from "./PendingWarningModal";

const NoCalenderModal = ({ isModal, setIsModal }) => {
    const navigate = useNavigate();

    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const [PendingModal, setdPendingModal] = useState(false);
    const [error, setError] = useState("");
    const active_session = state.user_details.active_session;
    const [isLoading, setIsLoading] = useState(false);

    const handleOuterClick = (e) => {
        const element = document.querySelector(".outer-click");
        if (!element.contains(e.target)) {
            setIsModal(false);
        }
    };

    const handleCancel = () => {
        setIsModal(false);
    };

    return (
        <>
            <Background
                onClick={(e) => isModal && handleOuterClick(e)}
            ></Background>

            <Container className="outer-click">
                <Title>Revoke Google Calendar</Title>
                <SubTitle>
                You don't have any Google Calendar account connected to My MindMitra.
                </SubTitle>
                <ButtonContainer>
                    <ButtonTypeOne
                        text="Close"
                        color={THEME_COLORS.dark_500}
                        bkgcolor={THEME_COLORS.white}
                        border={THEME_COLORS.light_400}
                        onclick={handleCancel}
                    />
                </ButtonContainer>
            </Container>
            {PendingModal && (
                <PendingWarningModal
                    isModal={PendingModal}
                    setIsModal={setdPendingModal}
                    DeleteWarnigModal={setIsModal}
                />
            )}
        </>
    );
};

export default NoCalenderModal;

const Background = styled.div`
    background: ${THEME_COLORS.elevation_expert_e9};
    position: fixed;
    opacity: 0.4;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 8888;
`;
const Container = styled.div`
    position: fixed;
    width: 411px;
    padding: 40px;
    background: ${THEME_COLORS.white};
    border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    border-radius: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    height: 300px;
    flex-direction: column;
    justify-content: center;
`;
const Title = styled.h4`
    font-weight: 600;
    font-size: 20px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 16px;
`;
const SubTitle = styled.p`
    font-weight: 400;
    font-size: 18px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 20px;
`;
const ButtonContainer = styled.div`
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
`;
