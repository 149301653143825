import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import close from "../../../assets/image/dashboard/notification-card/close-red.svg";
import tick from "../../../assets/image/dashboard/notification-card/tick-green.svg";
import person from "../../../assets/image/dashboard/notification-card/person.svg";
import emptyAlarm from "../../../assets/image/dashboard/empty-notification.svg";
import { THEME_COLORS } from "../../../ThemeConfig";
import NotificationDateChip from "../../includes/notificationchips/NotificationDateChip";
import CallTypeChip from "../../includes/Sessionchips/CallTypeChip";
import SessionCountChip from "../../includes/Sessionchips/SessionCountChip";
import { Context } from "../../../contexts/Store";
import { expert } from "../../../axiosConfig";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "./ConfirmationModal";

function NotificationContainer({ data, Sessions, setAction, action }) {
    const [isItem, setItem] = useState(false);
    const [sessionData, setSessionData] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState("");
    let user_details = localStorage.getItem("user_details");
    user_details = JSON.parse(user_details);
    const access_token = user_details.access_token;

    const getSessions = () => {
        expert
            .get("/sessions/new-coming/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                setSessionData(data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const handleAcceptSession = (id) => {
        const actions = {
            action: "accept",
        };
        expert
            .post(`sessions/new-coming/action/${id}/`, actions, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    getSessions();
                    Sessions();
                    setAction(!action);
                }
            })
            .catch((error) => {
                setRedirectUrl(error.response?.data?.data?.redirect_url);
                setShowConfirmationModal(true); // Show confirmation modal
            });
    };
    
    const handleRedirect = (url) => {
        window.location.href = url; 
    };

    const handleDeclineSession = (id) => {
        setAction(!action);
        const actions = {
            action: "decline",
        };
        expert
            .post(`sessions/new-coming/action/${id}/`, actions, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                getSessions();
                Sessions();
                setAction(!action);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    useEffect(() => {
        getSessions();
    }, []);

    const formatDateString = (dateString) => {
        const parts = dateString.split("-");
        const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        const date = new Date(formattedDate);

        if (isNaN(date.getTime())) {
            return "Invalid Date";
        }

        const options = { day: "numeric", month: "short" };
        return date.toLocaleString("en-US", options);
    };

    return (
        <>
            <NotificationDiv>
                <Top>
                    <Dot></Dot>
                    <Title>New Sessions</Title>
                </Top>
                {sessionData.length > 0 ? (
                    <BottomContainer status={sessionData?.length}>
                        {sessionData.map((item) => (
                            <Bottom key={item.id}>
                                <NotificationCard>
                                    <TopSection>
                                        <LeftSection>
                                            <AvatarContainer>
                                                {item.patient_details
                                                    .profile_photo ? (
                                                    <Avatar
                                                        src={
                                                            item.patient_details
                                                                .profile_photo
                                                        }
                                                        alt="icon"
                                                    />
                                                ) : (
                                                    <Avatar
                                                        src={person}
                                                        alt="icon"
                                                    />
                                                )}
                                            </AvatarContainer>
                                        </LeftSection>
                                        <RightSection>
                                            <DetailsContainer>
                                                <Name>
                                                    {
                                                        item.patient_details
                                                            .full_name
                                                    }
                                                </Name>
                                                <Details>
                                                    <Age>
                                                        {
                                                            item.patient_details
                                                                .age
                                                        }
                                                    </Age>
                                                    <div className="dot"></div>
                                                    <Gender>
                                                        {
                                                            item.patient_details
                                                                .gender
                                                        }
                                                    </Gender>
                                                    <div className="dot"></div>

                                                    {item?.package_details
                                                        ?.sessions_count ? (
                                                        <SessionCountChip
                                                            current_session={
                                                                "1"
                                                            }
                                                            total_session={
                                                                item
                                                                    .package_details
                                                                    .sessions_count
                                                            }
                                                            incoming={true}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </Details>
                                            </DetailsContainer>
                                            <Selection>
                                                <Decline>
                                                    <Icon
                                                        src={close}
                                                        onClick={() => {
                                                            setAction(!action);
                                                            handleDeclineSession(
                                                                item.id
                                                            );
                                                        }}
                                                    />
                                                </Decline>
                                                <Accept>
                                                    <Icon
                                                        src={tick}
                                                        onClick={() => {
                                                            setAction(!action);
                                                            handleAcceptSession(
                                                                item.id
                                                            );
                                                        }}
                                                    />
                                                </Accept>
                                            </Selection>
                                        </RightSection>
                                    </TopSection>
                                    <BottomSection>
                                        <Heading>
                                            {item.concern_details.title}
                                        </Heading>
                                        <Description
                                            className={
                                                item.id === isItem
                                                    ? "active"
                                                    : ""
                                            }
                                            onClick={() => {
                                                setItem(item.id);
                                            }}
                                        >
                                            {item.concern_details.description}
                                        </Description>
                                        <ButtonContainer>
                                            <Buttons>
                                                {item.schedule_details ? (
                                                    <NotificationDateChip
                                                        date={formatDateString(
                                                            item
                                                                .schedule_details
                                                                .date
                                                        )}
                                                        time={
                                                            item
                                                                .schedule_details
                                                                .time
                                                        }
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                                <CallTypeChip />
                                            </Buttons>
                                            <Text>{item.time_added}</Text>
                                        </ButtonContainer>
                                    </BottomSection>
                                </NotificationCard>
                            </Bottom>
                        ))}
                    </BottomContainer>
                ) : (
                    <EmptyBottomContainer>
                        <EmptyContainer>
                            <IconContainer>
                                <Icon src={emptyAlarm} />
                            </IconContainer>
                            <Text>
                                You don’t have any new session requests{" "}
                            </Text>
                        </EmptyContainer>
                    </EmptyBottomContainer>
                )}
            </NotificationDiv>
            <ConfirmationModal
                showConfirmationModal={showConfirmationModal}
                setShowConfirmationModal={setShowConfirmationModal}
                handleRedirect={handleRedirect}
                url={redirectUrl}
            />
        </>
    );
}

export default NotificationContainer;

const NotificationDiv = styled.div`
    padding: 0px 16px;
    box-shadow: ${THEME_COLORS.elevation_expert_e3};
    border-radius: 10px;
`;

const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-bottom: 1px solid ${THEME_COLORS.divider};
`;

const Dot = styled.div`
    background: ${THEME_COLORS.chips_red_on_container};
    width: 6px;
    height: 6px;
    border-radius: 20px;
    margin-right: 10px;
`;

const Title = styled.h2`
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
`;

const BottomContainer = styled.div`
    max-height: 300px;
    height: ${(props) =>
        props.status === 1 ? "100%" : props.status === 2 ? "400px" : "70vh"};
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Bottom = styled.div`
    padding: 20px 0;
    border-bottom: 1px solid ${THEME_COLORS.divider};
`;

const NotificationCard = styled.div``;

const TopSection = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

const LeftSection = styled.div`
    margin-right: 20px;
`;

const AvatarContainer = styled.div`
    border-radius: 57px;
    background: #f4f4f4;
    padding: 7px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
`;

const Avatar = styled.img`
    width: 100%;
    display: block;
`;

const RightSection = styled.div`
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-items: center;
    @media all and (max-width: 1400px) {
        flex-wrap: wrap;
    }
`;

const DetailsContainer = styled.div`
    width: 80%;
    @media all and (max-width: 1400px) {
        width: 100%;
        margin-bottom: 5px;
    }
`;

const Name = styled.h2`
    font-size: 16px;
    color: ${THEME_COLORS.chips_blue_on_container};
    margin-bottom: 5px;
`;

const Details = styled.div`
    display: flex;
    list-style: disc;
    align-items: center;
    div.dot {
        background: ${THEME_COLORS.text_para};
        width: 4px;
        height: 4px;
        border-radius: 20px;
        margin-right: 10px;
    }
`;

const Age = styled.p`
    margin-right: 10px;
    color: ${THEME_COLORS.text_para};
    font-size: 14px;
`;

const Gender = styled.p`
    margin-right: 10px;
    color: ${THEME_COLORS.text_para};
    font-size: 14px;
`;

const Selection = styled.div`
    display: flex;
`;

const Accept = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${THEME_COLORS.chips_green_container};
    border-radius: 20px;
    padding: 4px;
    margin-left: 10px;
    cursor: pointer;
`;

const Decline = styled.div`
    border: 1px solid ${THEME_COLORS.chips_red_container};
    border-radius: 20px;
    padding: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Icon = styled.img``;

const BottomSection = styled.div`
    padding: 12px;
    background: ${THEME_COLORS.chips_green_container};
    border-radius: 8px;
    cursor: pointer;
`;

const Heading = styled.h2`
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
`;

const Description = styled.p`
    height: 25px;
    overflow: hidden;
    transition: all 0.3s ease;
    &.active {
        height: auto;
        margin-bottom: 10px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Buttons = styled.div`
    display: flex;
`;

const Text = styled.p`
    color: ${THEME_COLORS.dark_200};
`;

const IconContainer = styled.div`
    margin-bottom: 30px;
`;

const EmptyContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 60%;
    text-align: center;
`;

const EmptyBottomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 350px;
    border-top: 1px solid ${THEME_COLORS.divider};
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

